import React, { useState } from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
// import Vimeo from "@vimeo/player";
import Vimeo from "@u-wave/react-vimeo";

// Import the responsive plugin, cloudinary & images map
import { AdvancedImage, placeholder, responsive } from "@cloudinary/react";
import { IMAGES } from "common/data/SaasModern";

import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import TiltShape from "../TiltShape";
import { BannerWrapper, Skeleton, VideoWrapper, ErrorMessage, ErrorWrapper } from "./banner.style";
import TrialDownloadButton from "../../Custom/TrialDownloadButton";
import UnderDownloadButtonText from "../../Custom/UnderDownloadButtonText";

// import Icon from 'react-icons-kit';
// import { openModal, closeModal } from './jebanyModal';
// import { openModal, closeModal } from '@redq/reuse-modal';
// import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';

/* // close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://player.vimeo.com/video/691765434"
      style={{
        border: '0px'
      }}
    />
  </VideoWrapper>
); */


const VideoError = ({ message }) => {
  return (
    <ErrorWrapper>
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorWrapper>
  )
}

const BannerSection = ({
  headingBoxWrapper,
  row,
  contentWrapper,
  videoWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  // this was above <Heading
  //   <DiscountWrapper>
  //   <DiscountLabel>
  //     <Text {...discountAmount} content="25% Save" />
  //     <Text
  //       {...discountText}
  //       content="for first month trail version"
  //     />
  //   </DiscountLabel>
  // </DiscountWrapper>

  /* const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  }; */

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container width="90%">
        <Box {...headingBoxWrapper}>
          <Heading {...title} content="See. Understand. Reduce AWS costs." />
        </Box>
      </Container>
      <Container width="90%">
        <Box {...row}>
          <Box {...contentWrapper}>
            <Text
              {...description}
              content="Control and optimize your AWS billing with an intuitive desktop application. "
            />
            <Text
              {...description}
              content="Cost-effective solution includes multiple AWS accounts, regardless of a bill size."
            />
            <Box {...buttonWrapper}>
              <TrialDownloadButton />
            </Box>
            <UnderDownloadButtonText color="#fff" />
          </Box>
          <Box {...videoWrapper}>
            <VideoWrapper>
              {/* <iframe
                title="Video"
                src="https://player.vimeo.com/video/691765434"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                style={{
                  border: "0px",
                }}
              /> */}
              {isLoading && <Skeleton />}
              {isError && <VideoError message="❌ Error loading video" />}
              <Vimeo video="691765434" autoplay pip={true} textTrack="en-US" quality="1080" onLoaded={handleLoad} onError={handleError} />
            </VideoWrapper>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row}>
          <Box {...imageWrapper}>
            <Fade bottom>
              <AdvancedImage
                cldImg={IMAGES.bannerImage}
                plugins={[responsive({ steps: 200 }), placeholder({ mode: 'blur'})]}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  headingBoxWrapper: PropTypes.object,
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  videoWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  headingBoxWrapper: {
    mt: ["1rem", "1rem", "7rem", "7rem", "7rem"],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    mb: "7rem",
  },
  contentWrapper: {
    width: ["100%", "100%", "40%", "40%", "43%"],
    mr: ["1rem", "1rem", "2rem", "2rem", "2rem"],
    mb: ["3rem", "3rem", "3rem", "3rem", "3rem"],
  },
  videoWrapper: {
    width: ["100%", "100%", "55%", "55%", "48%"],
    mb: ["3rem", "3rem", "3rem", "3rem", "3rem"],
  },
  imageWrapper: {
    width: "100%",
  },
  title: {
    fontSize: ["24px", "32px", "40px", "42px", "46px"],
    fontWeight: "700",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "50px", "50px"],
    lineHeight: "1.2",
    textAlign: "center",
  },
  description: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "#fff",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center",
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#00865b",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#fff",
    mb: 0,
    as: "span",
  },
  fillButton: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    colors: "secondaryWithBg",
    minWidth: ["auto", "150px"],
    height: ["40px", "46px"],
    minHeight: "auto",
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
    mt: "25px",
    mb: ["20px", "25px", "25px", "35px", "35px"],
  },
  button: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    color: "#fff",
    colors: "secondary",
    height: ["40px", "46px"],
    minHeight: "auto",
  },
};

export default BannerSection;
